import { captureException } from "@sentry/browser";
import { updateProfile, User } from "firebase/auth";
import {
  setDoc,
  doc,
  getDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { TUser } from "types";
import { toSlug } from "utils/browser";
import { currentUser, db, functions } from "utils/firebase";

export const setProfile = (data: Partial<Omit<TUser, "ts">>) => {
  const user = currentUser();
  const { email } = user;

  return setDoc(
    doc<TUser>(db as any, "users", email),
    {
      ...data,
    },
    { merge: true }
  );
};

export const updateNickname = async ({ nickname }: { nickname: string }) => {
  const user = currentUser();
  if (!user) return;
  const callableUpdateNickname = httpsCallable(functions, "updateNickname");
  await callableUpdateNickname(nickname);
  await setProfile({ displayName: nickname });
};

const setVanityUrl = async (user: User, slug: string) => {
  const setSlug = httpsCallable(functions, "setSlug");
  try {
    await setSlug(slug);
  } catch (e) {
    throw e;
  }
};

export const updateVanityUrl = async ({ slug }: { slug: string }) => {
  const user = currentUser();
  if (!user) return;
  await setVanityUrl(user, toSlug(slug));
};

export const setDisplayPicture = async ({
  displayPicture,
}: {
  displayPicture: string;
}) => {
  const user = currentUser();
  if (!user) return;
  await updateProfile(user, { photoURL: displayPicture });
  return setProfile({ displayPicture });
};

export const getUserData = async (email: string) => {
  const user = await getDoc(doc<TUser>(db as any, "users", email));
  return user.data();
};

export const getUserDataBySlug = async (slug: string) => {
  const docsRef = query<TUser>(
    collection(db, "users") as any,
    where("slug", "==", slug)
  );
  const docs = await getDocs(docsRef);
  const output: TUser[] = [];

  docs.forEach(docData => {
    output.push(docData.data());
  });

  if (output.length > 1) {
    captureException(new Error("DuplicateUserSlug"), {
      extra: {
        users: output.map(o => o.uid),
      },
    });
    throw new Error("DuplicateUserSlug");
  }

  return output[0];
};

export const setUserDescription = (description: string) =>
  setProfile({ description });

export const setUserPublic = (isPublic: boolean) => setProfile({ isPublic });

export const setEmailConfirmed = () => setProfile({ isEmailConfirmed: true });

import tlds from "tlds";

export const populatePathRegexp = (path, options) => {
  let output = path;
  Object.keys(options).forEach(key => {
    const re = new RegExp(`:${key}\\??`, "g");
    output = output.replace(re, options[key]);
  });
  return output;
};

export const toSlug = <T extends string>(
  string: string,
  separator = "_" as string | { replacement: string; strict?: boolean }
) => {
  const sep = typeof separator === "string" ? separator : separator.replacement;
  return (
    string
      ? string
          .replace(/[^0-9a-zA-Z]/g, sep)
          .replace(new RegExp(`${sep}{1,}`, "g"), sep)
      : ""
  ).toLowerCase() as T;
};

export const isURL = (url: string) => {
  // https://regex101.com/r/g1DoEJ/1
  const reUrls = new RegExp(
    `^(http|https)?(:\/\/)?(\\w+\\.)?\\w+\\.(?:${tlds.join("|")})`
  );
  return Array.isArray(url.match(reUrls));
};

export const getQueryStringToObject = <T>(): Partial<T> => {
  const qS = window.location.search.substring(1);
  const map: Partial<T> = {};
  qS.split("&")
    .map(keyvals => keyvals.split("="))
    .forEach(val => {
      map[val[0]] = val[1];
    });
  return map;
};

export const makeValidURL = (url: string) => {
  if (!Array.isArray(url.match(/^https?:\/\//))) {
    url = `https://${url}`;
  }
  return url;
};

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDuAMMdaLDRFBT5foEmABOprr6J9CG1qCQ",
  authDomain: "useridgg-4bab0.firebaseapp.com",
  projectId: "useridgg-4bab0",
  storageBucket: "useridgg-4bab0.appspot.com",
  messagingSenderId: "518793725011",
  appId: "1:518793725011:web:6e0b3a4fa7bd99bab5f2a6",
  measurementId: "G-J7ZCQ8ZRGS",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
if (process.env.USE_LOCAL === "true") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
export { auth, db, storage, functions };

export const currentUser = () => {
  const auth = getAuth(app);
  return auth.currentUser;
};
